import React from 'react';
import styled from 'styled-components';

import Icon from '../icon';
import appleTVImg from '../../../images/parceiros/appletv.svg';

const CardFooter = ({ plan, pageName }) => {
  const isExpress = pageName === 'express'
  const isPos = pageName === 'pos'

  const positionVas = (vas) =>{
    return vas.sort((a, b) => a.position - b.position);
  };

  return (
    <>
      <Footer>
        <>
          {plan.acumulateInternet && (
            <FlashSalesWrapper>
              <Plus>+</Plus>
              <GBAnimated>
                <p>GB</p>
              </GBAnimated>
              <FlashSalesText>Internet Acumulada</FlashSalesText>
            </FlashSalesWrapper>
          )}

          {plan.appletv && (
            <AppsWrapper>
              <Plus>+</Plus>
              <img
                src={appleTVImg}
                alt=""
                style={{
                  paddingBottom: 8,
                  marginRight: 10,
                }}
              />
              <p>Assinatura inclusa</p>
            </AppsWrapper>
          )}

         {plan.bonusDedicadoRedesSociais && (
          <AppsWrapper>
              <Plus>+</Plus>
              <AppList>
                {['whatsapp'].map((app, index) => (
                  <App key={index}>
                    <Icon className="card-icon" id={'icon-' + app} />
                  </App>
                ))}
                <p>Whatsapp incluso</p>
              </AppList>
            </AppsWrapper>
          )}

        {!plan.bonusDedicadoRedesSociais && (
          <AppsWrapper>
              <Plus>+</Plus>
              <AppList>
                {['whatsapp', 'instagram', 'facebook', 'x'].map((app, index) => (
                  <App key={index}>
                    <Icon className="card-icon" id={'icon-' + app} />
                  </App>
                ))}
              </AppList>
            </AppsWrapper>
          )}

          {plan.bonusDedicadoRedesSociais && (
            <AppsWrapper>
              <Plus>+</Plus>

              <AppList>
                {['instagram', 'facebook', 'x'].map((app, index) => (
                  <App key={index}>
                    <Icon className="card-icon" id={'icon-' + app} />
                  </App>
                ))}
                <li>

                <p style={{ color: '#004691' }}><strong>{plan.bonusDedicadoRedesSociais}</strong> Exclusivos para <br /> redes sociais</p>
                </li>
              </AppList>
            </AppsWrapper>
          )}

          {plan.bonusDedicadoRedesSociais && (
            <>
              <SubscriptionsText style={{ marginTop:' 10px'}}>
                Acesse o <strong>App Meu TIM</strong>, escolha um dos benefícios abaixo e troque todo mês (sem custo adicional ao plano)!
              </SubscriptionsText>

              <AppList>
                {['amazon', 'deezer', 'disney', 'globoplay', 'max', 'netflix', 'paramount', 'youtube'].map((vas, index) => (
                  <App isBigger key={index}>
                    <img src={`/images/vas-apps/icon-${vas}.svg`} alt={vas} />
                  </App>
                ))}
              </AppList>
            </>
          )}

          {plan?.vas && (
            <IncludedSubscriptions>
              <SubscriptionsText>Assinaturas inclusas:</SubscriptionsText>
              <AppList>
                {positionVas(plan.vas).map((vas, index) => (
                  <App isBigger key={index}>
                    <img src={vas.imageUrl} alt={vas.text} />
                  </App>
                ))}
              </AppList>
            </IncludedSubscriptions>
          )}

          <MoreInfo>
            <ul>
              {(isPos || (isExpress && plan.order === 1)) && (
                <>
                  <li>
                    <img src="/images/icon-phone.svg" alt="Celular" />
                    <p>Ligações ilimitadas para todas as operadoras usando o código 41</p>
                  </li>
                  <li>
                    <img src="/images/icon-chat.svg" alt="Chat" />
                    <p>SMS ilimitados para todas as operadoras usando o código 41</p>
                  </li>
                </>
              )}

              {(isExpress && (plan.order === 2 || plan.order === 3)) || isPos ? (
                <li>
                  <img src="/images/icon-flight.svg" alt="Avião" />
                  <p>Roaming Internacional - Pacote Américas {plan.order === 3 && '+ Europa'}</p>
                </li>
              ) : null}

              {isPos && (
                <>
                  <li>
                    <img src="/images/icon-flight.svg" alt="Avião" />
                    <p>TIM no Avião</p>
                  </li>
                  <li>
                    <img src="/images/icon-wifi.svg" alt="Wi-fi" />
                    <p>Concierge</p>
                  </li>
                </>
              )}
            </ul>


          </MoreInfo>

        </>
      </Footer>
    </>
  );
};

const Text = styled.p`
  color: ${({ theme }) => theme.palette.primary};
  font-size: 14px;
`;

const AppsWrapper = styled.div`
  opacity: ${({ isTransparency }) => (isTransparency ? '0.2' : '1')};
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  > ${Text} {
    margin: 0 0 5px 0;
  }

  p {
    font-size: 13px;
    line-height: 18.97px;
    color: #4a4a4a;
    margin-bottom: 2px;
  }

  img {
    width: 80px;
  }
`;

export const GBAnimated = styled.div`
  margin: 0 10px 0 5px;
  position: relative;
  &:after {
    content: '';
    display: block;
    background: url('/images/circle-arrow.svg') no-repeat center;
    background-size: 30px;
    position: absolute;
    top: -11px;
    left: -8px;
    width: 40px;
    height: 40px;
    z-index: 1;
    animation: infinite-spinning 1s infinite;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    color: #23507a;
    z-index: 3;
  }
  @keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const AppList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: ${(props) => props.noVideoPartners && '61px'};
  gap: 4px;

  p {
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0px;
    align-self: center;
  }
`;

const Footer = styled.footer`
  align-items: flex-start;
  background: #f2f2f2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px 14px;
  width: 100%;
  flex: 1 0 auto;
`;

const IncludedSubscriptions = styled.div`
  margin: 20px 0;
`;

const SubscriptionsText = styled(Text)`
  color: #4a4a4a;
  font-size: 14px;
  margin-bottom: 6px;
`;

const MoreInfo = styled(SubscriptionsText)`
  text-align: left;
  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    li {
      display: flex;
      align-items: flex-start;
      gap: 4px;
    }
  }
  & + p {
    letter-spacing: -0.63px;
    margin: 10px 0 0;
  }
`;

const Plus = styled.span`
  color: ${({ theme }) => theme.palette.secondary};
  font-weight: bold;
  font-size: 32px;
  display: inline-block;
  margin-right: 5px;
  line-height: 1;
`;

const FlashSalesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const FlashSalesText = styled(Text)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
`;

const App = styled.li`
  width: ${(p) => (p.isBigger ? '30px' : '24px')};
  height: ${(p) => (p.isBigger ? '30px' : '24px')};
  text-align: center;
  > svg,
  > img {
    width: ${(p) => (p.isBigger ? '30px' : '24px')};
    height: ${(p) => (p.isBigger ? '30px' : '24px')};
  }
`;

export default CardFooter;
